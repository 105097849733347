import { User as UserEntity } from '@core/entities/user/user.entity';
import { mapSerializrEmailAddressEntity } from '@core/mappers/serializr-entities/user/email-address.mapper';
import { User } from '@core/models/user/user';
import { mapSerializrPhoneNumber } from '@core/mappers/serializr-entities/user/phone-number.mapper';
import { mapSerializrAddress } from '@core/mappers/serializr-entities/user/address.mapper';

export function mapSerializrUser(u: UserEntity): User {
  return {
    emailAddresses: u.emails.map((e) => {
      return mapSerializrEmailAddressEntity(e);
    }),
    bankAccount: u.bankAccount,
    firstName: u.firstName,
    lastName: u.lastName,
    phoneNumbers: u.phones.map((p) => {
      return mapSerializrPhoneNumber(p);
    }),
    postalAddresses: u.postalAddresses.map((pa) => {
      return mapSerializrAddress(pa);
    }),
    testUser: u.isTestUser,
    uuid: u.uuid,
  };
}
