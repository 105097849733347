import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailAddress } from '@core/models/email-address/email-address';
import { PostEmailAddressResponse } from '@core/models/responses/id-api/post-email-address/post-email-address-response';
import idApiConfig from '@config/apis/id/id-api.config';
import { environment } from '@environments/environment';
import {
  DeleteEmailAddressResponse,
} from '@core/models/responses/id-api/delete-email-address/delete-email-address-response';
import {
  PostSendEmailAddressVerificationEmailResponse,
} from '@core/models/responses/id-api/post-send-email-address-verification-email/post-send-email-address-verification-email-response';
import {
  PostSetPrimaryEmailAddressResponse,
} from '@core/models/responses/id-api/post-set-primary-email-address/post-set-primary-email-address-response';
import { HttpClient } from '@angular/common/http';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';

@Injectable({
  providedIn: 'root',
})
export class EmailAddressService {
  #httpClient = inject(HttpClient);

  postEmailAddress(emailAddress: string, userUuid: string): Observable<PostEmailAddressResponse> {
    return this.#httpClient
      .post<PostEmailAddressResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_EMAIL_ADDRESS.path,
        ).toString(),
        {
          email: {
            email: emailAddress,
          },
          frontEndUrl: environment.frontEnd.baseUrl,
          verifyUrl: environment.frontEnd.verifyEmailAddressUrl,
          userUuid,
        },
      );
  }

  deleteEmailAddress(emailAddress: EmailAddress): Observable<DeleteEmailAddressResponse> {
    return this.#httpClient
      .delete<DeleteEmailAddressResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.DELETE_EMAIL_ADDRESS.path,
          { uuid: emailAddress.uuid },
        ).toString(),
      );
  }

  postRequestEmailAddressVerification(
    emailAddress: EmailAddress,
  ): Observable<PostSendEmailAddressVerificationEmailResponse> {
    return this.#httpClient
      .post<PostSendEmailAddressVerificationEmailResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL.path,
          { uuid: emailAddress.uuid },
        ).toString(),
        {
          verifyUrl: environment.frontEnd.verifyEmailAddressUrl,
        },
      );
  }

  postSetPrimaryEmailAddress(emailAddress: EmailAddress): Observable<PostSetPrimaryEmailAddressResponse> {
    return this.#httpClient
      .post<PostSetPrimaryEmailAddressResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_SET_PRIMARY_EMAIL_ADDRESS.path,
          { uuid: emailAddress.uuid },
        ).toString(),
        null,
      );
  }
}
