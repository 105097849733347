import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NameFormValue } from '@core/models/user/name-form-value';
import { PutSelfResponse } from '@core/models/responses/id-api/put-self/put-self-response';
import idApiConfig from '@config/apis/id/id-api.config';
import { Observable } from 'rxjs';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';
import { User } from '@core/models/user/user';

@Injectable({
  providedIn: 'root',
})
export class SelfService {
  #httpClient = inject(HttpClient);

  putUserNames(user: User, names: NameFormValue): Observable<PutSelfResponse> {
    return this.#httpClient
      .put<PutSelfResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.PUT_SELF.path,
        ).toString(),
        {
          user: {
            ...names,
            bankAccount: user.bankAccount || null
          },
        },
      );
  };

  putBankAccountNumber(user: User, bankAccountNumber: string): Observable<PutSelfResponse> {
    return this.#httpClient.put<PutSelfResponse>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.PUT_SELF.path,
      ).toString(),
      {
        user: {
          firstName: user.firstName,
          lastName: user.lastName,
          bankAccount: bankAccountNumber,
        },
      },
    );
  };
}
