import { Phone } from '@core/entities/user/phone.entity';
import { PhoneNumber } from '@core/models/phone-number/phone-number';

export function mapSerializrPhoneNumber(p: Phone): PhoneNumber {
  return {
    uuid: p.uuid,
    phoneNumber: p.phoneNumber,
    primary: p.primary,
    verifiedAt: p.verifiedAt
  };
}
