import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { parsePhoneNumber } from 'libphonenumber-js';

export class PhoneNumberValidator {
  static syntax(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return parsePhoneNumber(value, 'NO').isValid() ? null : { syntax: true };
    };
  }
}
