import { EmailAddress } from '@core/models/email-address/email-address';
import { Email } from '@core/entities/user/email.entity';

export function mapSerializrEmailAddressEntity(e: Email): EmailAddress {
  return {
    uuid: e.uuid,
    emailAddress: e.emailAddress,
    primary: e.primary,
    verifiedAt: e.verifiedAt,
  };
}
