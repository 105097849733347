import { AsyncValidator, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { FfNgxPhoneNumberHelper, FfNgxRequestService } from '@fagforbundet/ngx-components';
import idApiConfig from '@config/apis/id/id-api.config';
import {
  GetValidatePhoneNumberResponse,
} from '@core/models/responses/id-api/get-validate-phone-number/get-validate-phone-number-response';

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberValidator implements AsyncValidator {
  constructor(private readonly _requestService: FfNgxRequestService) {}

  validate: AsyncValidatorFn = ({
    value: { countryCode, number },
  }): Observable<ValidationErrors | null> => {
    let formattedPhoneNumber;

    try {
      formattedPhoneNumber = FfNgxPhoneNumberHelper.formatPhoneNumber(
        FfNgxPhoneNumberHelper.concatPhoneNumber(number, countryCode),
      );
    } catch (e) {
      return of(null);
    }

    return this._requestService
      .get<GetValidatePhoneNumberResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.GET_VALIDATE_PHONE_NUMBER,
        endpointVars: {
          phoneNumber: formattedPhoneNumber,
        },
      })
      .pipe(
        map((response) => {
          if (response.responseBody.valid) {
            return null;
          }

          return {
            invalid: {},
          };
        }),
      );
  };
}
