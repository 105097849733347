import { EmailAddress } from '@core/models/email-address/email-address';

export function postEmailAddressMapper(emailAddressDto: {
  uuid: string,
  email: string,
  primary: boolean,
  verifiedAt?: Date,
}): EmailAddress {
  return {
    uuid: emailAddressDto.uuid,
    emailAddress: emailAddressDto.email,
    primary: emailAddressDto.primary,
    verifiedAt: emailAddressDto.verifiedAt,
  };
}
