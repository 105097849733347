import idApiConfig from '@config/apis/id/id-api.config';
import { FfNgxRequestData } from '@fagforbundet/ngx-components';

export const getPhoneCodesRequest = (
  lang: 'no' | string,
): FfNgxRequestData<typeof idApiConfig> => {
  return {
    api: idApiConfig,
    endpoint: idApiConfig.endpoints.GET_PHONE_CODES,
    endpointVars: { lang },
  };
};
