import { inject, Injectable } from '@angular/core';
import { FfNgxRequestService } from '@fagforbundet/ngx-components';
import { RequestServiceInMemoryStoreNames } from '@core/enums/request-service-in-memory-store-names';
import { map, Observable } from 'rxjs';
import { GetCountriesResponse } from '@core/models/responses/id-api/get-countries/get-countries-response';
import { getCountriesRequest } from '@core/models/responses/id-api/get-countries/get-countries-request';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  #requestService = inject(FfNgxRequestService);

  fetchCountries(): Observable<{ code: string, name: string }[]> {
    return this.#requestService.get<GetCountriesResponse>(getCountriesRequest('nb-NO'), {
      store: RequestServiceInMemoryStoreNames.STORE_COUNTRIES,
      cacheTtl: 604800000,
    })
      .pipe(
        map((response) => {
          return response.responseBody.countries;
        }),
      );
  }
}
