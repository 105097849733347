import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PostalAddressFormValue } from '@core/models/postal-address/postal-address-form-value';
import {
  PostPostalAddressResponse,
} from '@core/models/responses/id-api/post-postal-address/post-postal-address-response';
import idApiConfig from '@config/apis/id/id-api.config';
import { PutPostalAddressResponse } from '@core/models/responses/id-api/put-postal-address/put-postal-address-response';
import { Observable } from 'rxjs';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';

@Injectable({
  providedIn: 'root',
})
export class PostalAddressService {
  #httpClient = inject(HttpClient);

  postPostalAddress(
    postalAddress: PostalAddressFormValue,
    userUuid: string,
  ): Observable<PostPostalAddressResponse> {
    return this.#httpClient
      .post<PostPostalAddressResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_POSTAL_ADDRESS.path,
        ).toString(),
        {
          address: { ...postalAddress, city: postalAddress.postalArea },
          userUuid,
        },
      );
  };

  putPostalAddress(
    postalAddress: PostalAddressFormValue,
  ): Observable<PutPostalAddressResponse> {
    return this.#httpClient
      .put<PutPostalAddressResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.PUT_POSTAL_ADDRESS.path,
          { uuid: postalAddress.uuid },
        ).toString(),
        {
          address: { ...postalAddress, city: postalAddress.postalArea },
        },
      );
  };
}
