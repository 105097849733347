import { inject, Injectable } from '@angular/core';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';
import { PhoneNumberFormValue } from '@core/models/phone-number/phone-number-form-value';
import { Observable } from 'rxjs';
import { PhoneNumber } from '@core/models/phone-number/phone-number';
import { PostPhoneNumberResponse } from '@core/models/responses/id-api/post-phone-number/post-phone-number-response';
import idApiConfig from '@config/apis/id/id-api.config';
import {
  DeletePhoneNumberResponse,
} from '@core/models/responses/id-api/delete-phone-number/delete-phone-number-response';
import {
  PostSendPhoneNumberVerificationSmsResponse,
} from '@core/models/responses/id-api/post-send-phone-number-verification-sms/post-send-phone-number-verification-sms-response';
import {
  PostSetPrimaryPhoneNumberResponse,
} from '@core/models/responses/id-api/post-set-primary-phone-number/post-set-primary-phone-number-response';
import {
  PostVerifyPhoneNumberResponse,
} from '@core/models/responses/id-api/post-verify-phone-number/post-verify-phone-number-response';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberService {
  #httpClient = inject(HttpClient);

  postPhoneNumber(
    phoneNumber: PhoneNumberFormValue,
    userUuid: string,
  ): Observable<PostPhoneNumberResponse> {
    return this.#httpClient
      .post<PostPhoneNumberResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_PHONE_NUMBER.path,
        ).toString(),
        {
          phone: {
            number: `+${phoneNumber.countryCode}${phoneNumber.number}`,
          },
          userUuid,
        },
      );
  }

  deletePhoneNumber(phoneNumber: PhoneNumber): Observable<DeletePhoneNumberResponse> {
    return this.#httpClient
      .delete<DeletePhoneNumberResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.DELETE_PHONE_NUMBER.path,
          { uuid: phoneNumber.uuid },
        ).toString(),
      );
  }

  postRequestSendVerificationSms(phoneNumber: PhoneNumber): Observable<PostSendPhoneNumberVerificationSmsResponse> {
    return this.#httpClient
      .post<PostSendPhoneNumberVerificationSmsResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_SEND_PHONE_NUMBER_VERIFICATION_SMS.path,
          { uuid: phoneNumber.uuid },
        ).toString(),
        null,
      );
  }

  postSetPrimaryPhoneNumber(phoneNumber: PhoneNumber): Observable<PostSetPrimaryPhoneNumberResponse> {
    return this.#httpClient
      .post<PostSetPrimaryPhoneNumberResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_SET_PRIMARY_PHONE_NUMBER.path,
          { uuid: phoneNumber.uuid },
        ).toString(),
        null,
      );
  }

  postPhoneNumberVerification(
    phoneNumber: PhoneNumber,
    code: string,
  ): Observable<PostVerifyPhoneNumberResponse> {
    return this.#httpClient
      .post<PostVerifyPhoneNumberResponse>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_VERIFY_PHONE_NUMBER.path,
          {
            uuid: phoneNumber.uuid,
            code,
          },
        ).toString(),
        null,
      );
  }
}
