import { inject, Injectable } from '@angular/core';
import { FfNgxRequestService } from '@fagforbundet/ngx-components';
import { GetPhoneCodesResponse } from '@core/models/responses/id-api/get-phone-codes/get-phone-codes-response';
import { getPhoneCodesRequest } from '@core/models/responses/id-api/get-phone-codes/get-phone-codes-request';
import { RequestServiceInMemoryStoreNames } from '@core/enums/request-service-in-memory-store-names';
import { map, Observable } from 'rxjs';
import { PhoneCountryCode } from '@core/models/phone-country-code/phone-country-code';

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberCountryCodeService {
  #requestService = inject(FfNgxRequestService);

  fetchCountryCodes(): Observable<PhoneCountryCode[]> {
    return this.#requestService.get<GetPhoneCodesResponse>(getPhoneCodesRequest('no'), {
      store: RequestServiceInMemoryStoreNames.STORE_PHONE_COUNTRY_CODES,
    })
      .pipe(
        map((response) => {
          return response.responseBody.phoneCountryCodes;
        }),
      );
  }
}
