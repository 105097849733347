import { PhoneNumber } from '@core/models/phone-number/phone-number';

export function postPhoneNumberMapper(phoneNumberDto: {
  uuid: string,
  number: string,
  countryCode: string,
  primary: boolean,
  createdAt: Date,
  verifiedAt?: Date,
}): PhoneNumber {
  return {
    uuid: phoneNumberDto.uuid,
    phoneNumber: `+${phoneNumberDto.countryCode}${phoneNumberDto.number}`,
    primary: phoneNumberDto.primary,
    verifiedAt: phoneNumberDto.verifiedAt,
  };
}
