import idApiConfig from '@config/apis/id/id-api.config';
import { FfNgxRequestData } from '@fagforbundet/ngx-components';

export const getCountriesRequest = (
  lang: 'nb-NO' | string,
): FfNgxRequestData<typeof idApiConfig> => {
  return {
    api: idApiConfig,
    endpoint: idApiConfig.endpoints.GET_COUNTRIES,
    endpointVars: {
      lang,
    },
  };
};
