import { Address } from '@core/entities/user/address.entity';
import { PostalAddress } from '@core/models/postal-address/postal-address';

export function mapSerializrAddress(a: Address): PostalAddress {
  return {
    country: a.country,
    countryCode: a.countryCode,
    line1: a.line1,
    line2: a.line2,
    line3: a.line3,
    postalArea: a.postalArea,
    postalCode: a.postalCode,
    uuid: a.uuid,
  };
}
